
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as datenschutz8Idu2tRmArMeta } from "/app/pages/datenschutz.vue?macro=true";
import { default as impressumLDkFRkkSAmMeta } from "/app/pages/impressum.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as component_45stub0E2cSFosOlMeta } from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_howzdcxp37fg32tfjp3jfzf74a/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub0E2cSFosOl } from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_howzdcxp37fg32tfjp3jfzf74a/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "datenschutz",
    path: "/datenschutz",
    component: () => import("/app/pages/datenschutz.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("/app/pages/impressum.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: component_45stub0E2cSFosOlMeta?.name,
    path: "/startseite/terzo%C2%AETinnitustherapie",
    component: component_45stub0E2cSFosOl
  },
  {
    name: component_45stub0E2cSFosOlMeta?.name,
    path: "/startseite/terzo-tinnitustherapie",
    component: component_45stub0E2cSFosOl
  }
]