<template>
  <NuxtPage />
</template>

<script setup lang="ts">
import { environmentKey, isEditorKey, storyblokEnvKey, storyblokOptionsKey } from "./injection-symbols"

const route = useRoute()
const environment
  = (useRuntimeConfig().public.siteEnv as "production" | "staging" | "development")
  || (process.env.NODE_ENV as "production" | "development")
const storyblokEnv = environment === "development" || environment === "staging" ? "draft" : "published"

useHead({
  link: [
    { rel: "mask-icon", href: "/favicon-mask.svg", type: "image/svg+xml", color: "#08213D", sizes: "696x696" },
    { rel: "apple-touch-icon", href: "/safari-favicon.svg", type: "image/svg+xml" },
    { rel: "apple-touch-icon", href: "/safari-favicon.png", type: "image/png", sizes: "512x512" },
    { rel: "icon", href: "/favicon.svg", type: "image/svg+xml" },
    { rel: "icon", href: "/favicon-dark.svg", type: "image/svg+xml", media: "(prefers-color-scheme:dark)" },
    { rel: "icon", href: "/favicon.png", type: "image/png" },
    { rel: "icon", href: "/favicon-dark.png", type: "image/png", media: "(prefers-color-scheme:dark)" },
    { rel: "manifest", href: "/manifest.webmanifest" },
    { rel: "preconnect", href: "https://js.hscollectedforms.net" },
    { rel: "preconnect", href: "https://js.hs-banner.com" },
  ],
  meta: [{ name: "google-site-verification", content: "eSP2xf92wuLfTTMFd1NumfbxqbodAxS_limwUj2YE4U" }],
})

useSeoMeta({
  colorScheme: "light dark",
  ogType: "website",
  themeColor: [
    { content: "#08213D", media: "(prefers-color-scheme: dark)" },
    { content: "#CEF2E0", media: "(prefers-color-scheme: light)" },
  ],
  twitterCard: "summary_large_image",
  appleMobileWebAppCapable: "yes",
  appleMobileWebAppStatusBarStyle: "default",
  formatDetection: "telephone=no",
})

provide(environmentKey, environment)
provide(storyblokEnvKey, storyblokEnv)
provide(isEditorKey, !!route.query._storyblok)
provide(storyblokOptionsKey, await useSbOptions(storyblokEnv))
</script>

<style lang="scss">
:is(.page-enter-active, .page-leave-active, .page-reverse-enter-active, .page-reverse-leave-active) {
  @apply duration-150;

  main {
    @apply transition-[transform,opacity];
  }
}

:is(.page-enter-from, .page-leave-to, .page-reverse-enter-from, .page-reverse-leave-to) main {
  @apply translate-x-0 opacity-0;
}

:is(.page-leave-to, .page-reverse-enter-from) main {
  @apply -translate-x-4;
}

:is(.page-enter-from, .page-reverse-leave-to) main {
  @apply translate-x-4;
}
</style>
