import { environmentKey } from "~/injection-symbols"

export const useTracking = () => {
  const environment = inject(environmentKey)

  if (environment === "production") {
    const route = useRoute()

    useHead({
      script: [
        {
          id: "hs-script-init",
          async: false,
          defer: false,
          innerHTML: `var _hsq=window._hsq=window._hsq||[];_hsq.push(['setPath','${route.path}']);_hsq.push(['setContentType', 'site-page']);var _hsp=window._hsp=window._hsp||[];`,
        },
        {
          type: "text/javascript",
          id: "hs-script-loader",
          async: true,
          defer: true,
          src: "//js.hs-scripts.com/8184800.js",
        },
        {
          id: "gtag-script-init",
          innerHTML:
            "window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments);}gtag('js',new Date());gtag('config','G-P99T4SPWC5');",
        },
      ],
    })

    if (useDevice().isCrawler) {
      useHead({ script: [{ id: "gtag-script-loader-crawler", async: true, src: "https://www.googletagmanager.com/gtag/js?id=G-P99T4SPWC5" }] })
    } else {
      const scripts = [] as any[]
      const links = [] as any[]

      withConsent(
        consent => {
          if (consent.categories.analytics) {
            gtag("set", "allow_ad_personalization_signals", true)

            useHead({
              script: [

                {
                  type: "text/javascript",
                  id: "gtag-script-loader",
                  async: true,
                  defer: true,
                  src: "https://www.googletagmanager.com/gtag/js?id=G-P99T4SPWC5",
                },
                {
                  id: "meta-script-loader-init",
                  async: true,
                  defer: true,
                  innerHTML:
                      "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('consent', 'revoke');fbq('init', '1019144132716050');fbq('track', 'PageView');",
                },
              ],
              link: [
              // per https://developer.chrome.com/docs/lighthouse/performance/uses-rel-preconnect
                { rel: "preconnect", href: "https://fonts.googleapis.com" },
                { rel: "preconnect", href: "https://js.hs-analytics.net" },
                { rel: "preconnect", href: "https://js.hsadspixel.net" },
                { rel: "preconnect", href: "https://connect.facebook.net" },
              ],
            })

            onMounted(() => {
              hubspotTrack("setPath", useRoute().path)
              hubspotTrack("trackPageView")
              googleTagTrack("event", "page_view", { page_path: useRoute().path })
              metaTrack("track", "PageView")
              metaTrack("consent", "revoke")
            })
          } else {
            gtag("set", "allow_ad_personalization_signals", false)
          }

          console.log("scripts", scripts)
          useHead({ script: scripts, link: links })
        },
      )
    }
  } else {
    useSeoMeta({ robots: "noindex" })
    console.warn("tracking disabled in non-production environment")
  }
}
