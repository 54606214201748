import revive_payload_client_5VxOYcJaRc from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_howzdcxp37fg32tfjp3jfzf74a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ysJUx1gh5f from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_howzdcxp37fg32tfjp3jfzf74a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_KBwxo9J4zc from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_howzdcxp37fg32tfjp3jfzf74a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_TQ4CVZN8nC from "/app/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.5_jiti@2.4.2_uw7oc33sp7sh5gzjqpin6wdsbu/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_VDUFNeSS0w from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_howzdcxp37fg32tfjp3jfzf74a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_La3p6QIm47 from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_howzdcxp37fg32tfjp3jfzf74a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_llb0v7URP8 from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_howzdcxp37fg32tfjp3jfzf74a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GiEgfPFtOR from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_howzdcxp37fg32tfjp3jfzf74a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_9PZGru47Fc from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.5_db0@0.2.1_eslint@9.17.0_jiti@2.4.2__ior_howzdcxp37fg32tfjp3jfzf74a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import siteConfig_lytb85UHWN from "/app/node_modules/.pnpm/nuxt-seo-utils@6.0.5_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.5_jiti@2.4.2_s_nb45rf65fv2g32s4aoazwcwiwy/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_J9k02xkuie from "/app/node_modules/.pnpm/nuxt-seo-utils@6.0.5_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.5_jiti@2.4.2_s_nb45rf65fv2g32s4aoazwcwiwy/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_cBwtaubmXZ from "/app/node_modules/.pnpm/nuxt-seo-utils@6.0.5_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.5_jiti@2.4.2_s_nb45rf65fv2g32s4aoazwcwiwy/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import plugin_YxMi7af8UZ from "/app/node_modules/.pnpm/@storyblok+nuxt@6.2.1_vue@3.5.13_typescript@5.7.2_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import defaults_CcCIlJJ80p from "/app/node_modules/.pnpm/nuxt-seo-utils@6.0.5_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.5_jiti@2.4.2_s_nb45rf65fv2g32s4aoazwcwiwy/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  revive_payload_client_5VxOYcJaRc,
  unhead_ysJUx1gh5f,
  router_KBwxo9J4zc,
  _0_siteConfig_TQ4CVZN8nC,
  payload_client_VDUFNeSS0w,
  navigation_repaint_client_La3p6QIm47,
  check_outdated_build_client_llb0v7URP8,
  chunk_reload_client_GiEgfPFtOR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_9PZGru47Fc,
  plugin_wy0B721ODc,
  siteConfig_lytb85UHWN,
  inferSeoMetaPlugin_J9k02xkuie,
  titles_cBwtaubmXZ,
  plugin_YxMi7af8UZ,
  defaults_CcCIlJJ80p
]