<template>
  <slot />
  <template v-if="dialogBloks.length">
    <auto-dialog
      v-for="dialogBlok in dialogBloks"
      :id="`dialog-/${dialogBlok.full_slug}`"
      ref="dialogEls"
      :key="dialogBlok.content._uid"
      v-editable="dialogBlok"
      :headline="dialogBlok.content.headline"
    >
      <storyblok-component v-for="childBlok in dialogBlok.content.body" :key="childBlok._uid" :blok="childBlok" />
    </auto-dialog>
  </template>
</template>

<script setup lang="ts">
import type { ISbStoryData } from "@storyblok/vue"
import type { MultipageDialogStoryblok } from "~/storyblok-component-types"
import type autoDialog from "~/components/auto-dialog.vue"
import { openDialogKey } from "~/injection-symbols"

const dialogBloks = ref<ISbStoryData<MultipageDialogStoryblok>[]>([])
const dialogUrls = ref<string[]>([])
const dialogEls = ref<Array<typeof autoDialog>>([])

// dialogs are opened via sb-link.vue but inside of <page> so we need to provide the openDialog function here
// this is to avoid multiple dialogs with the same content
async function openDialog(dialogUrl: string, loadOnly = false) {
  const existingDialog = dialogEls.value.find(dialog => dialog.id === `dialog-${dialogUrl}`)

  if (existingDialog) {
    if (!loadOnly) existingDialog.open()
  } else if (!dialogUrls.value.includes(dialogUrl)) {
    dialogUrls.value.push(dialogUrl)

    const dialogStory = (await useAsyncStoryblok(dialogUrl)) as Ref<ISbStoryData<MultipageDialogStoryblok>>
    if (dialogStory.value) dialogBloks.value.push(dialogStory.value)
  }
}

// TODO: crashes the app when updating "nuxt" package, need to investigate
provide(openDialogKey, openDialog)
</script>
